<template>
    <div class="page-content">
        <page-breadcrumb title="Transfer NFT Management" class="mb-3" />
        <section class="py-1">
            <form-generator :schema="filter_schema" :model="filter" />
            <b-card>
                <data-table-ssr id="transfer-nft-management" ref="transfer_nft_management" :limit-base="9"
                    pagination
                    :get-list-fn="getList" :columns="table_field"
                />
            </b-card>
        </section>
    </div>
</template>
<script>
import service from "../service"
const status_options = [
    {text: "COMPLETED", value: 1},
    {text: "PENDING", value: 2}
]
const side_options = [
    {text: "IN", value: 1},
    {text: "OUT", value: 0}
]
const table_field = [
  { label: 'User ID', field: 'user_id' },
  { label: 'Fee', field: 'fee' },
  { label: 'Token Address', field: 'token_addr' },
  { label: 'Side', field: 'side', type: 'array', input_type: 'select', options: side_options },
  {
    label: 'Status', field: 'status', type: 'array', input_type: 'select', options: status_options,
  },
  { label: 'Occur Time', field: 'occur_time', input_type: 'datetime' },
];
const filter_schema = [
  { cols: 4, fields: [{ label: 'User ID', field: 'user_id' }] },
  { cols: 4, fields: [{ label: 'Side', field: 'side', input_type: 'select', options: side_options}] },
  { cols: 4, fields: [{ label: 'Status', field: 'status', input_type: 'select', options: status_options}] },
  { cols: 6, fields: [{ label: 'Occur Time', field: 'time_range', input_type:"datetime_range"}] }
];
export default {
    data(){
        return {
            export_data: [],
            filter: {
                time_range:[
                    new Date(new Date().getFullYear(), new Date().getMonth(), 1),
                    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
                ]
            },
            table_field,
            filter_schema,
            statusObj:{
                1: "COMPLETED",
                2: "PENDING"
            },
            sideObj:{
                1:"IN",
                0:"OUT"
            }
        }
    },
     watch: {
        current_page() {
            this.getList();
        },
        filter: {
            handler() {
                if (this.filter.time_range.match(/to/i)){
                    this.$refs.transfer_nft_management.getList();
                }
            },
            deep: true,
        },
    },
    
    methods: {
        async getList({ limit, page, query }) {

            let list = [], total = 0;
            let response = await service.getList({ query: { ...query, ...this.filter }, page, limit });
            if (response.data.type === "DATA") {
                list = response.data.data.list;
                total = response.data.data.total;
            }
            return { list, total };
        },
    }
}
</script>